<template>
  <div class="personal">
    <!-- 订单信息 -->
    <div class="personalA clearfix">
      <div class="personal_title clearfix">
        <!-- <span style="padding-left: 15px">订单详情</span> -->
        <span style="padding-left: 15px; font-size: 14px; color: #333">{{ list.OrderCode }}</span>
        <span style="float: right;padding-right: 15px; font-size: 14px;">
          <span v-if="list.Status == 1" style="color: #3baef5">{{ list.StatusDesc }}</span>
          <span v-if="list.Status == 2" style="color: #E6A23C">{{ list.StatusDesc }}</span>
          <span v-if="list.Status == 3" style="color: #ffc002">{{ list.StatusDesc }}</span>
          <span v-if="list.Status == 4" style="color: #67c23a">{{ list.StatusDesc }}</span>
          <span v-if="list.Status == 8" style="color: #fc7d0a">{{ list.StatusDesc }}</span>
          <span v-if="list.Status == 9" style="color: #e61549">{{ list.StatusDesc }}</span>
        </span>
      </div>
      <div>
        <!-- <div class="personal_img clearfix">
          <img
            v-if="list.GoodsImg != null && list.GoodsImg != ''"
            :src="list.GoodsImg.split(',')[0]"
            :onerror="onErrorImg('https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png')"
            alt=""
            class="imgurl"
          />
          <img
            v-else
            src="https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png"
            class="imgurl"
            style="object-fit: cover;border-radius: 8px;"
          />
        </div> -->
        <div class="personal_info clearfix" style="line-height: 25px ">
          <div>点单时间: {{ list.GenerateTime }}</div>
          <div>下单时间: {{ list.OrderTime }}</div>
          <div>下单人: {{ list.OrderMan }}</div>
          <div>联系电话: {{ list.OrderManTel }}</div>
          <div>
            商品数量 :{{ list.GoodsNum }} <span style="padding-left: 10px"></span>
            <span
              >共 <span style="color: red;">¥{{ list.Amount }}</span
              >元</span
            >
          </div>
          <!-- <div style="margin: 4px 0px">
            订单状态:
            <span v-if="list.Status == 1" style="color: #3baef5">{{ list.StatusDesc }}</span>
            <span v-if="list.Status == 2" style="color: #E6A23C">{{ list.StatusDesc }}</span>
            <span v-if="list.Status == 3" style="color: #ffc002">{{ list.StatusDesc }}</span>
            <span v-if="list.Status == 4" style="color: #67c23a">{{ list.StatusDesc }}</span>
            <span v-if="list.Status == 8" style="color: #fc7d0a">{{ list.StatusDesc }}</span>
            <span v-if="list.Status == 9" style="color: #e61549">{{ list.StatusDesc }}</span>
          </div> -->
          <!-- 待接单 -->
          <div v-if="list.Status == 2">
            <div>商户交易号: {{ list.TradeNo }}</div>
          </div>
          <!-- 已接单 -->
          <div v-if="list.Status == 3">
            <div>商户交易号: {{ list.TradeNo }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单时间: {{ list.RecTime }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单人: {{ list.StaRecMan }}</div>
          </div>
          <!-- 已完成 -->
          <div v-if="list.Status == 4">
            <div>商户交易号: {{ list.TradeNo }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单时间: {{ list.RecTime }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单人: {{ list.StaRecMan }}</div>
            <div>结单时间: {{ list.FinishTime }}</div>
            <div>结单人: {{ list.FinishMan }}</div>
          </div>
          <!-- 已退回 -->
          <div v-if="list.Status == 8">
            <div>商户交易号: {{ list.TradeNo }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单时间: {{ list.RecTime }}</div>
            <div v-if="list.RecTime != null && list.RecTime != ''">接单人: {{ list.StaRecMan }}</div>
            <div>退回时间: {{ list.RtnTime }}</div>
            <div>退回原因: {{ list.RtnReason }}</div>
            <div>支付状态: {{ list.PayStatusDesc }}</div>
            <div>退款单号: {{ list.RtnTradeNo }}</div>
            <div>退款时间: {{ list.RefundTime }}</div>
          </div>
          <!-- 已撤销 -->
          <div v-if="list.Status == 9">
            <div>商户交易号: {{ list.TradeNo }}</div>
            <div>支付状态: {{ list.PayStatusDesc }}</div>
            <div>撤单时间: {{ list.RevTime }}</div>
            <div>撤单原因: {{ list.RevReason }}</div>
            <div>退款单号: {{ list.RtnTradeNo }}</div>
            <div>退款时间: {{ list.RefundTime }}</div>
          </div>

          <!-- <div style="margin: 4px 0px">下单时间 :{{ list.GenerateTime }}</div> -->
        </div>
      </div>
    </div>

    <!-- 订单信息 -->
    <div class="personalA clearfix" style="margin-bottom: 60px">
      <div class="personal_title">
        <span style="padding-left: 15px">下单商品</span>
      </div>
      <!-- <div class="personal_MX clearfix">
        <span class="clearfix">订单类型</span>
        <span class="clearfix">{{ list.OTypeDesc }}</span>
      </div>
      <div class="personal_MX clearfix">
        <span class="clearfix">下单时间</span>
        <span class="clearfix">{{ list.OrderTime }}</span>
      </div> -->
      <!-- 服务列表 -->
      <div v-for="item in fuwulist" :key="item.index">
        <div style="display: flow-root">
          <div class="personal_img clearfix">
            <img
              :src="item.GoodsImg"
              :onerror="onErrorImg('https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png')"
              alt=""
              class="imgurl"
            />
          </div>
          <div class="personal_info clearfix" style="width: 61%;">
            <div style="position: relative">
              <span>名称 :{{ item.GoodsName }}</span>
            </div>

            <!-- <div style="margin: 4px 0px">分类 :{{ item.SerKindDesc }}</div> -->
            <div style="margin: 4px 0px">数量 :{{ item.GoodsNum }}</div>
            <div style="margin: 4px 0px">金额 :{{ item.Price }} {{ item.MUnit }}</div>
          </div>
        </div>
      </div>
      <!-- <div v-if="list.Status == '2'"
        class="personal_Buttton clearfix">
        <button style="background: #1989fa; margin-left: 10%; float: left"
          @click="getRevokeOrder(list.OrderId)">
          撤 单
        </button>
        <button style=" margin-right: 10%; float: right;background:#ffc002;"
          @click="orderRen(list.OrderId)">
          下 单
        </button>
      </div> -->
      <!-- <div class="order" v-if="list.Status == '2'">
        <button @click="order(list.OrderId)" style="background: #ffc002">
          接 单
        </button>
      </div>
      <div class="order" v-if="list.Status == '3'">
        <button @click="orderOver(list.OrderId)" style="background: #ffc002">
          结 单
        </button>
      </div> -->
    </div>
    <div class="personalA clearfix" v-if="list.EvaStatus == '2'">
      <div>
        <div class="personal_info clearfix">
          <div style="position: relative">
            <span></span>
          </div>
          <div style="margin: 4px 0px">
            <van-rate
              v-model="list.Satisfaction"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly
            >
            </van-rate>
          </div>
          <div style="margin: 4px 0px">
            评价时间 :{{ list.EvaTime }}
            <div style="margin: 4px 0px">{{ list.EvalContent }}</div>
            <div style="margin: 4px 0px" v-if="list.EvalImgs != null && list.EvalImgs != ''">
              <span v-for="(imgurl, index) in list.EvalImgs.split(',')" :key="index">
                <img
                  :src="url + imgurl"
                  :onerror="onErrorImg('https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png')"
                  class="imgurl"
                  style="margin: 10px"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model="arriveShow" title="提示" show-cancel-button :beforeClose="beforeClose">
      <van-field v-model="dataForm.RevReason" label="撤单原因" placeholder="请输入撤单原因" />
    </van-dialog>
    <van-action-sheet v-model="orderRenshow" title="配送老人" @close="close()">
      <div class="content">
        <van-radio-group
          v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="
            margin: 10px;
            padding: 15px 10px;
            background: #f6f7f8;
            border-radius: 5px;
          "
        >
          <van-radio :name="item.BindId" checked-color="#ffc002" style="padding: 20rpx">
            <div
              style="
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10rpx;
                margin: 5px;
              "
            >
              {{ item.OMName }}
              <span style="font-weight: 500">{{ item.OMMobile }}</span>
              <span style="padding-left: 20px" @click="updata(item)"> <van-icon name="edit"/></span>
              <span style="padding-left: 20px" @click="addOldMan()"> <van-icon name="add-o"/></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px">{{ item.OMAddr }}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
            <van-button
              round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 100%; float: none"
              color="black"
              @click="addOrder()"
            >
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="orderRenAddshow" title="配送老人信息" @close="close()">
      <div class="content">
        <van-field
          v-model="oldmanForm.OMIdCard"
          class="shuru"
          name="oldmanForm.OMIdCard"
          label="身份证号"
          placeholder="请输入身份证号"
        />
        <van-field
          v-model="oldmanForm.OMMobile"
          class="shuru"
          name="oldmanForm.OMMobile"
          label="联系电话"
          placeholder="请输入老人联系电话"
        />
        <van-field
          v-model="oldmanForm.OMAddr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.OMAddr"
          label="居住地址"
          placeholder="请输入老人现居住地址"
        />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round block type="info" color="black" @click="submit()">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import Vue from 'vue'
import { Dialog } from 'vant'
import { Toast } from 'vant'
Vue.use(Toast)
Vue.use(Dialog)
import {
  WeGetMyOrder,
  WeRevokeOrder,
  WeGetMyBindInfoPage,
  WeSaveBindInfo,
  WePlaceOrder,
  WeGetOrderGoodsList,
} from '@/api/ylOrder.js'
import { WxRecviceOrder, WxFinishOrder } from '@/api/RealInfo.js'
import { setOpenId, getOpenId, removeOrderId } from '@/utils/auth'
export default {
  data() {
    return {
      list: [],
      fuwulist: [],
      url: 'http://lycaljdapi.dexian.ren/',
      arriveShow: false,
      dataForm: {
        RevReason: '',
      },
      oid: '',
      oldManList: '',
      orderRenAddshow: false,
      orderRenshow: false,
      MyBindInfoList: [],
      oldmanForm: {
        OMIdCard: '',
        OMMobile: '',
        OMAddr: '',
        BindId: undefined,
      },
    }
  },
  mounted() {
    this.getlist()
  },
  methods: {
    onErrorImg: function(imgSrc) {
      return 'this.οnerrοr=null;this.src=' + '"' + imgSrc + '";'
    },
    // 确认接单
    order() {
      Dialog.confirm({
        title: '提示',
        message: '是否确认接单!',
      })
        .then(() => {
          WxRecviceOrder({
            openID: getOpenId(),
            OrderId: this.$route.params.OId,
          }).then((res) => {
            if (res.data.code == 0) {
              Toast.success('接单成功!')
              this.getlist()
            } else {
              Toast.fail(res.data.msg)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 确认结单
    orderOver() {
      Dialog.confirm({
        title: '提示',
        message: '是否完成结单',
      })
        .then(() => {
          WxFinishOrder({
            openID: getOpenId(),
            OrderId: this.$route.params.OId,
          }).then((res) => {
            if (res.data.code == 0) {
              Toast.success('结单成功!')
              this.getlist()
            } else {
              Toast.fail(res.data.msg)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    // 获取分页
    getlist() {
      WeGetMyOrder({
        openID: getOpenId(),
        orderId: this.$route.params.OId,
      }).then((res) => {
        this.list = res.data.data
      })
      WeGetOrderGoodsList({
        openID: getOpenId(),
        orderId: this.$route.params.OId,
      }).then((res) => {
        console.log(res)
        this.fuwulist = res.data.data
      })
    },
    // 撤单
    getRevokeOrder: function(row) {
      this.arriveShow = true
      this.oid = row
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        setTimeout(done, 500)
        WeRevokeOrder({
          OpenID: getOpenId(),
          OrderId: this.oid,
          RevReason: this.dataForm.RevReason,
        }).then((res) => {
          if (res.data.code == 0) {
            Toast.success('撤单成功')
            this.oid = ''
            this.getlist()
          }
        })
      } else if (action === 'cancel') {
        done() //关闭
      }
    },
    // 评价
    appraise: function(row) {
      this.$router.push('/ylOrder/orderList/appraise/' + row)
    },
    close: function() {
      // console.log(123123);
      this.oldManList = ''
      this.oldmanForm.OMIdCard = ''
      this.oldmanForm.OMMobile = ''
      this.oldmanForm.OMAddr = ''
    },
    // 下单人
    orderRen: function(row) {
      console.log(row)
      this.orId = row
      this.orderRenshow = true
      this.getMyBindInfoPage()
    },
    //下单
    addOrder: function() {
      WePlaceOrder({
        OpenID: getOpenId(),
        OrderId: this.orId,
        BindId: this.oldManList,
      }).then((res) => {
        if (res.data.code == '0') {
          Toast.success('下单成功')
          this.getlist()
          this.showPopupShow = false
          this.orderRenshow = false
        } else {
          Toast.fail(res.data.msg)
        }
      })
      // })
    },
    getMyBindInfoPage: function() {
      WeGetMyBindInfoPage({
        openID: getOpenId(),
        page: '1',
        limit: '999',
      }).then((res) => {
        if (res.data.code == '0') {
          if (res.data.data.length != '0') {
            this.MyBindInfoList = res.data.data
            // this.orderListShow = false
          } else {
            // Toast.fail('未绑定老人信息');
            this.MyBindInfoList = []
            this.orderRenshow = false
            this.orderRenAddshow = true
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg)
        }
      })
    },
    // 添加老人信息
    submit() {
      console.log(this.oldmanForm)
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail('身份证号码不正确')
      } else if (this.oldmanForm.OMMobile == '') {
        Toast.fail('请输入老人联系电话')
      } else if (this.oldmanForm.OMAddr == '') {
        Toast.fail('请输入老人现居住地址')
      } else {
        WeSaveBindInfo({
          OpenID: getOpenId(),
          OMIdCard: this.oldmanForm.OMIdCard,
          OMMobile: this.oldmanForm.OMMobile,
          OMAddr: this.oldmanForm.OMAddr,
          BindId: this.oldmanForm.BindId,
        }).then((res) => {
          if (res.data.code == '0') {
            Toast.success('绑定成功')
            this.orderRenAddshow = false
            this.orderRenshow = true
            this.oldmanForm.OMIdCard = ''
            this.oldmanForm.OMMobile = ''
            this.oldmanForm.OMAddr = ''
            this.oldmanForm.BindId = undefined
            this.getMyBindInfoPage()
          } else {
            Toast.fail(res.data.msg)
          }
        })
      }
    },
    addOldMan: function() {
      this.orderRenAddshow = true
    },
    // 编辑老人信息
    updata: function(row) {
      this.orderRenAddshow = true
      console.log(row)
      this.oldmanForm = Object.assign({}, row)
    },
  },
}
</script>
<style scoped>
.personal {
  background: #f9f9f9;
  min-height: 100vh;
}
.van-action-sheet {
  height: 500px !important;
}
.personalA {
  background: #fff;
  width: 90%;
  margin: 15px 5%;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 5px #ddd;
}
.personalA .personal_title {
  height: 45px;
  width: 100%;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
}
.personalA .personal_img {
  float: left;
  margin: 9px 15px;
}
.personalA .personal_info {
  /* width: 61%; */
  width: calc(100% - 20px);
  margin: 10px 15px;
  float: left;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX {
  float: left;
  width: 100%;
  font-size: 14px;
  color: #333;
}
.personalA .personal_MX span:nth-child(1) {
  float: left;
  display: inline-block;
  margin: 8px 15px;
  width: 25%;
}
.personalA .personal_MX span:nth-child(2) {
  float: right;
  margin: 8px 15px;
  display: inline-block;
  width: 55%;
}
.personalA .personal_Buttton {
  width: 100%;
}
.personalA .personal_Buttton button {
  width: 30%;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
}
.order {
  width: 100%;
}
.order button {
  width: 90%;
  margin: 0 5% 15px;
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 45px;
  background: #3497fb;
}
.imgurl {
  width: 65px;
  height: 65px;
  border-radius: 8px;
  /* border: 1px solid #ff7e1e; */
}
</style>
